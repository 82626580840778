import styles from './Footer.module.scss';

import Link from 'next/link';
import { useContext } from 'react';
import {ABContext} from '../../contexts/ABContext';

export function Footer() {

  const ab = useContext(ABContext);

  const contactUsLink = '/contact-us';

  return (
    <footer className={ styles.footer }>
      <div className={ styles.contact }>
        <div>
          <a href={ contactUsLink }>
            Contact Us
          </a>
        </div>
        <div>
          Need assistance with your order? <span>Call Attraction Tickets LTD: <a href="tel:0800 408 4834">0800 408 4834</a></span>
        </div>
      </div>

      <hr/>

      <div className={ styles.nav }>
        <div>
        © {(new Date().getFullYear())} Universal Studios. All rights reserved.<br/>
        Some imagery shown throughout our website does not represent current operational and safety guidelines{ ab.isActiveTest("CRO-1158-footer-fullstop") ? null : "." }
        </div>

        <div>
          <ul>
            <li><a href="https://www.universalorlando.com/web/en/us/terms-of-service/copyright" >Copyright &amp; Trademark</a></li>
            <li><Link href="/privacy-policy">Privacy Policy</Link></li>
            <li><Link href="/terms-conditions">Terms of Service</Link></li>
            <li><a href="https://www.universalorlando.com/web/en/us/plan-your-visit/accessibility-information">Accessibility Information</a></li>
          </ul>
        </div>

      </div>

    </footer>
  )
}
